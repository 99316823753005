import React from "react"
import { graphql } from "gatsby"
import Slider from "react-slick"
// import Loadable from "react-loadable"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import BodySections from "../components/BodySections/BodySections"
import { Section, Container } from "../components/Containers"
import { Image, Text } from "../components/Core"
import { AnchorLinksBar, An } from "../components/AnchorLinksBar"
import { AccordionGroup } from "../components/Accordion"

const loader = () => <></>

// const FareharborLoadable = Loadable({
//   loader: () => import("../hooks/useFareharbor"),
//   loading: loader
// })

const EventsPage = ({ pageContext, data }) => {
  const { metaTitle, metaDescription, socialImage, schemaPage } = pageContext
  const {
    title,
    hero,
    anchors,
    sections,
    safety,
    tours,
    charters,
    reviews,
    shop
  } = data.allUniquePagesJson.nodes[0]

  const rootUrl = title.substring(0, title.length - 1)

  const settings = {
    className: "slider variable-width",
    dots: false,
    autoplay: true,
    speed: 2000,
    infinite: false,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight: true,
    dots: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          infinite: false,
          centerMode: false,
          variableWidth: false
        }
      }
    ]
  }

  return (
    <Layout className="events-page">
      <SEO
        title={metaTitle}
        description={metaDescription}
        socialImage={socialImage}
        schemaPage={schemaPage}
      />
      <Image wrapperClassName="gradient-curtain" publicId={hero.image} useAR />
      <AnchorLinksBar rootUrl={rootUrl} links={anchors} />

      <BodySections sections={sections} />

      {/* <Section id="fh-calendar" smallSpacing colorBack>
        <Container small>
          <div id="fh-calendar">
            <FareharborLoadable />
          </div>
        </Container>
      </Section> */}

      <Container small>
        <Section>
          <Text
            style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
            text={safety}
          />
        </Section>
      </Container>

      <Section colorBack>
        <Container small>
          <Text className="mb-3" text={tours.content} />
          <AccordionGroup items={tours.accordion} />
        </Container>
      </Section>

      <Section>
        <Container small>
          <Text className="mb-3" text={charters.content} />
          <AccordionGroup items={charters.accordion} />
        </Container>
      </Section>

      <Section zeroBottom zeroTop className="reviews">
        <Text as="h4" className="reviews__heading" text={reviews.heading} />
        <Slider {...settings}>
          {reviews.quotes.map((quote, i) => (
            <Text className="reviews__quote" key={i} text={quote} />
          ))}
        </Slider>
      </Section>
      {/* <div className="reviews__gallery">
        {reviews.images.map(img => (
          <Image publicId={img} key={img} />
        ))}
      </div> */}
      {/* <Section>
        <Container small>
          <Text text={shop.content} />
        </Container>
      </Section> */}
    </Layout>
  )
}

export const eventsQuery = graphql`
  query eventsquery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        hero {
          image
        }
        anchors {
          text
          id
        }
        sections {
          type
          text
          images
          colorBack
          element
          id
          buttons {
            button {
              buttonText
              appearance
              destination
              href
            }
          }
          imageText {
            reverse
            text
            image
          }
          container
        }
        safety
        tours {
          content
          accordion {
            label
            content
          }
        }
        charters {
          content
          accordion {
            label
            content
          }
        }
        reviews {
          heading
          quotes
          images
        }
        shop {
          content
          images
        }
      }
    }
  }
`

export default EventsPage
